<!-- Home Section -->
<section id="home" class="main">
     <div class="overlay"></div>

     <div class="container">

          <div class="row">

               <div class="col-md-6 col-sm-7 col-xs-12">
                    <div class="home-thumb">
                         <h1 class="wow fadeInUp" data-wow-delay="0.6s">పెళ్లి సంబంధం Free Matrimonial App</h1>

                         <p class="wow fadeInUp" data-wow-delay="0.8s">Upload profile, share and find match with free of
                              cost.</p>
                         <a href="https://play.google.com/store/apps/details?id=com.pellisambandham.pellisambandham"
                              class="wow fadeInUp section-btn btn btn-success smoothScroll" data-wow-delay="1s">Download
                              App</a>
                    </div>
               </div>
               <div class="wow fadeInUp col-md-6 col-sm-5 col-xs-10 col-xs-offset-1 col-sm-offset-0"
                    data-wow-delay="0.2s">
                    <img src="assets/images/home-img.png" style="width: 150px;;" class="img-responsive" alt="Home">
               </div>
          </div>
     </div>
</section>


<!-- How to use Section -->

<section id="about">
     <div class="container">
          <div class="row">
               <div class="col-md-2 col-sm-12" style="text-align:center;">
                    <img src="assets/images/umamaheswra.jpg" style="width:60px;height: 70px;">

               </div>
               <div class="col-md-7 col-sm-12" style="text-align: center;">
                    <img src="assets/images/lordvenkateswara.png" style="width:100px;">

               </div>
               <div class="col-md-2 col-sm-12" style="text-align: center;">
                    <img src="assets/images/srisitaramulu.png" style="width:70px;">
               </div>


          </div>


          <div class="row">


               <div class="col-md-12 col-sm-12">

                    <div class="wow bounceIn section-title">

                         <h2>పెళ్లి సంబంధం - Matrimonial App </h2>
                         <h2>Free Matrimonial App</h2>
                         <h3 class="wow fadeInUp" data-wow-delay="0.8s">upload profile, share and find match with free
                              of cost.</h3>
                         <hr>
                    </div>
               </div>

               <div class="wow fadeInUp col-md-7 col-sm-12" data-wow-delay="0.4s">

                    <p class="wow fadeInUp" data-wow-delay="0.4s">వధువు మరియు వరుడి ప్రొఫైల్‌లను ఇతరులకు చూపించడానికి
                         మరియు ఓ మంచి సంబంధం వెతుకుటకు ఇది ఒక Free Mobile App.</p>

                    <hr>
                    <ul class="wow fadeInUp">
                         <li>
                              <p>మీ మొబైల్ నంబర్‌ను ఉపయోగించి సభ్యునిగా చేరండి. మధ్యవర్తులు కూడా సభ్యులుగా చేరవచ్చు</p>
                         </li>

                         <li>
                              <p>మీ ప్రొఫైల్‌ను ఇతరులకు చూపించే ముందు, మా సేవా బృందం ఎటువంటి అబ్యన్తర భాష మరియు ఫోటోలు
                                   లేని యెడల అనుమతిస్తుంది.</p>
                         </li>
                         <li>
                              <p>మీరు మీ ప్రొఫైల్‌లను ఎప్పుడైనా నియంత్రించవచ్చు.</p>
                         </li>
                         <li>
                              <p>మీరు ప్రొఫైల్‌లను అందరికి చూపిస్తూ, Photos ని మీరు అనుమతి ఇచ్చిన వారు మాత్రమే చూసె
                                   విదముగా నియంత్రించవచ్చు.</p>
                         </li>
                         <li>
                              <p>మీ ప్రొఫైల్‌కు Listing Service ఇక అవసరం లేని యెడల, దయచేసి ప్రొఫైల్‌ను App నుంచి
                                   తొలగించండి.</p>
                         </li>

                    </ul>
                    <hr>
                    <p>WhatsApp Groups లో ప్రొఫైల్‌లను పలుసార్లు Post చేసె బదులు, మీరు ఒక్కసారి అప్‌లోడ్ చేసి
                         ప్రొఫైల్‌లను Share చేయవచ్చు. ఇలా చేయటం వలన ఎక్కువ మందిని చేరుకొనే అవకాశం ఉంటుంది. </p>
               </div>

               <div class="wow fadeInUp col-md-5 col-sm-12" data-wow-delay="0.4s">

                    <p class="wow fadeInUp" data-wow-delay="0.4s">This is a free mobile application to manage profiles
                         of Bride &amp; Groom, to share and find a good match.</p>

                    <hr>
                    <ul class="wow fadeInUp">
                         <li>
                              <p>Join as a member using your mobile number. Mediators can also join as member.</p>
                         </li>

                         <li>
                              <p>Before showing your profile to others, our service team will verify the language and
                                   photos. If nothing is offensive then they will approve.</p>
                         </li>
                         <li>
                              <p>You can control your profile visibility anytime.</p>
                         </li>
                         <li>
                              <p>You can keep Profiles for public access and Photos for private access. You can grant
                                   view permission to a specific members</p>
                         </li>
                         <li>
                              <p>Please remove the profiles which are no longer required listing service.</p>
                         </li>
                    </ul>
                    <hr>
                    <p> Instead of sharing profiles in WhatsApp groups multiple times, you can just upload once and
                         share profiles. This way your profile may reach many people.</p>

               </div>


          </div>


     </div>
</section>





<!-- Modal Contact -->

<div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
     <div class="modal-dialog">
          <div class="modal-content modal-popup">
               <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">&times;</span></button>
                    <h2 class="modal-title">Contact Form</h2>
               </div>

               <form action="#" method="post">
                    <input name="name" type="text" class="form-control" id="name" placeholder="Your Name" required>
                    <input name="email" type="email" class="form-control" id="email" placeholder="Email Address"
                         required>
                    <textarea name="message" rows="3" class="form-control" id="message" placeholder="Message"
                         required></textarea>
                    <input name="submit" type="submit" class="form-control" id="submit" value="Send Message">
               </form>
          </div>
     </div>
</div>


<!-- Back top -->

<a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>